import { Component, isDevMode, OnInit } from "@angular/core";
import { NavigationEnd, Router } from "@angular/router";
import { AnalyticsConfiguration } from "../analytics-configuration";

declare let gtag: Function;

@Component({
   selector: 'ap-google-analytics',
   template: ''
})
export class GoogleAnalyticsComponent implements OnInit {
   constructor(public router: Router, private config: AnalyticsConfiguration) {
      if (!isDevMode()) {
         this.setUpGoogleAnalytics(this.config.googleAnalyticsTrackingCode, this.config.googleTagManagerScriptUrl);
         this.observeRouterNavigationEnd();
      }
   }

   ngOnInit(): void {}

   private setUpGoogleAnalytics(trackingCode: string, tagManagerScriptUrl: string): void {
      // register google tag manager
      const gTagManagerScript = document.createElement('script');
      gTagManagerScript.async = true;
      gTagManagerScript.src = tagManagerScriptUrl + trackingCode;
      document.head.appendChild(gTagManagerScript);

      // register google analytics
      const gaScript = document.createElement('script');
      gaScript.innerHTML = `
        window.dataLayer = window.dataLayer || [];
        function gtag() { dataLayer.push(arguments); }
        gtag('js', new Date());
        gtag('config', '${trackingCode}', { send_page_view: false});
      `;
      document.head.appendChild(gaScript);
   }

   private observeRouterNavigationEnd(): void {
      this.router.events.subscribe(event => {
         if (event instanceof NavigationEnd) {
            gtag('event', 'page_view', {
               page_location: event.urlAfterRedirects
            });
         }
      });
   }
}
