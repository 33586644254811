import { ModuleWithProviders, NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { GoogleAnalyticsComponent } from "./";
import { AnalyticsConfiguration, AnalyticsConfigurationParams } from "./analytics-configuration";

@NgModule({
   declarations: [GoogleAnalyticsComponent],
   imports: [CommonModule],
   exports: [GoogleAnalyticsComponent]
})
export class AnalyticsModule {
   static forRoot(params: AnalyticsConfigurationParams): ModuleWithProviders<AnalyticsModule> {
      return {
         ngModule: AnalyticsModule,
         providers: [
            {
               provide: AnalyticsConfiguration,
               useValue: params
            }
         ]
      };
   }
}
