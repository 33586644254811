import { Injectable } from "@angular/core";

@Injectable({
   providedIn: 'root'
})
export class AnalyticsConfiguration {
   googleAnalyticsTrackingCode = '';
   googleTagManagerScriptUrl = '';
}

export interface AnalyticsConfigurationParams {
   googleAnalyticsTrackingCode?: string;
   googleTagManagerScriptUrl?: string;
}
