import { Component, OnDestroy, OnInit, ViewContainerRef } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MAT_MOMENT_DATE_FORMATS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { DatePickerLocalizationService } from '@root/src/app/services/date-picker-localization.service';
import { ViewContainerRefService } from '@root/src/app/services/view-container-ref.service';
import { DbCreationService } from '@root/src/app/services/db-creation.service';

@Component({
   selector: 'app-root',
   templateUrl: './app.component.html',
   styleUrls: ['./app.component.scss'],
   providers: [
      { provide: MAT_DATE_LOCALE, useValue: 'en-us' },
      { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
      { provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS },
      DatePickerLocalizationService
   ]
})
export class AppComponent implements OnDestroy, OnInit {
   title = 'Field Analytics Demo';

   constructor(
      private titleService: Title,
      private dbCreationService: DbCreationService,
      public viewContainerRef: ViewContainerRef,
      private containerService: ViewContainerRefService,
      private dpLocalizeService: DatePickerLocalizationService
   ) {
      this.titleService.setTitle(this.title);
   }

   ngOnDestroy(): void {}

   public ngOnInit(): void {
      this.containerService.setContainer(this.viewContainerRef);
   }
}
