import { Injectable, ViewContainerRef } from "@angular/core";

@Injectable({
  providedIn: 'root'
})
export class ViewContainerRefService {
  container?: ViewContainerRef;

  constructor() { }

  setContainer(viewContainerRef: ViewContainerRef): void {
    this.container = viewContainerRef;
  }

  get(): ViewContainerRef {
    if (!this.container) throw new Error('ViewContainerRef is not initialized');
    return this.container;
  }
}
