import { NgModule } from "@angular/core";
import { HttpClient, HttpClientModule } from "@angular/common/http";
import { TranslateLoader, TranslateModule, TranslateService } from "@ngx-translate/core";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { TranslateCacheModule, TranslateCacheService, TranslateCacheSettings } from "ngx-translate-cache";
import { CookieService } from "ngx-cookie-service";

@NgModule({
   imports: [
      HttpClientModule,
      TranslateModule.forChild({
         loader: {
            provide: TranslateLoader,
            useFactory: translateLoaderFactory,
            deps: [HttpClient]
         }
      }),
      TranslateCacheModule.forRoot({
         cacheService: {
            provide: TranslateCacheService,
            useFactory: translateCacheFactory,
            deps: [TranslateService, TranslateCacheSettings]
         },
         cacheMechanism: 'LocalStorage'
      })
   ],
   exports: [TranslateModule, TranslateCacheModule]
})
export class I18nModule {
   constructor(
      translate: TranslateService,
      translateCacheService: TranslateCacheService,
      cookieService: CookieService
   ) {
      translateCacheService.init();
      translate.langs = ['en-us', 'fr-fr'];
      const browserLang = translateCacheService.getCachedLanguage() || translate.getBrowserCultureLang() || 'en-us';
      translate.use(/en-us|fr-fr/.exec(browserLang.toLowerCase()) ? browserLang.toLowerCase() : 'en-us');
      translate.onLangChange.subscribe(l => cookieService.set('lang', l.lang, { expires: 100, path: '/' }));
   }
}

export function translateLoaderFactory(httpClient: HttpClient) {
   return new TranslateHttpLoader(httpClient);
}

export function translateCacheFactory(
   translateService: TranslateService,
   translateCacheSettings: TranslateCacheSettings
) {
   return new TranslateCacheService(translateService, translateCacheSettings);
}
