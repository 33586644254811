// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  sccApiRootUrl: "https://scc-backend.stg.ap.6grain.com",
  gatewayApiRootUrl: "https://localhost:5001",
  aggregatesApiRootUrl: "https://localhost:5001",
  resourceServerAllowedUrls: ["https://localhost:5001"],
  tilesUrl: "https://localhost:5001/api/v1/files",
  databaseName: 'zambiaDemoDbDev',
  googleAnalyticsTrackingCode: "",
  googleTagManagerScriptUrl: ""
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
