import { Inject, Injectable } from "@angular/core";
import { DateAdapter, MAT_DATE_FORMATS } from "@angular/material/core";
import { TranslateService } from "@ngx-translate/core";
import * as moment from "moment/moment";

@Injectable()
export class DatePickerLocalizationService {
   private translateLang?: string = '';

   constructor(
      private dateAdapter: DateAdapter<any>,
      private translate: TranslateService,
      @Inject(MAT_DATE_FORMATS) public data: any
   ) {
      this.setLocation();
      this.observeTranslations();
   }

   private observeTranslations() {
      this.translate.onLangChange.subscribe(l => {
         this.setLocation();
      });
   }

   private setLocation(): void {
      const adapterFormat = moment.localeData(this.translate.currentLang).longDateFormat('L');
      this.data.parse.dateInput = adapterFormat;
      this.data.display.dateInput = adapterFormat;
      this.dateAdapter.setLocale(this.translate.currentLang);
   }
}
