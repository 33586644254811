import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { VersionWidgetComponent } from "./version-widget/version-widget.component";
import { FlexLayoutModule } from "@angular/flex-layout";
import { I18nModule } from "@root/src/app/i18n/i18n.module";

@NgModule({
   declarations: [VersionWidgetComponent],
   imports: [CommonModule, FlexLayoutModule, I18nModule],
   exports: [VersionWidgetComponent]
})
export class WidgetsModule {}
