import { Component } from "@angular/core";
import * as packageInfo from "@root/package.json";

@Component({
   selector: 'version-widget',
   templateUrl: './version-widget.component.html',
   styleUrls: ['./version-widget.component.scss']
})
export class VersionWidgetComponent {
   public version: string = (packageInfo as any).default.version;
}
