import { Injectable } from "@angular/core";
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Observable, throwError } from "rxjs";
import { TranslateService } from "@ngx-translate/core";
import { catchError } from "rxjs/operators";

@Injectable()
export class AcceptLanguageInterceptor implements HttpInterceptor {
   constructor(private translateService: TranslateService) {}

   intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<any>> {
      const header = this.translateService.currentLang;
      const headers = request.headers.set('accept-language', header);
      if (!request.url.includes('/assets/i18n/')) request = request.clone({ headers });

      return next.handle(request).pipe(catchError(err => throwError(err)));
   }
}
