import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@root/src/environments/environment';
import {ReplaySubject} from "rxjs";

const DB_STRUCTURE_URL = 'assets/database/db_structure.json';

@Injectable({
  providedIn: 'root',
})
export class DbCreationService {
  public database?: IDBDatabase;
  public isReady = new ReplaySubject(1);

  constructor(private http: HttpClient) {
    this.http
      .get<{ version: number; stores: { name: string; key: string }[]; storesToDelete: string[] }>(DB_STRUCTURE_URL)
      .subscribe((dbStructure) => {
        const req = self.indexedDB.open(environment.databaseName, dbStructure.version);
        req.onsuccess = (ev) => {
          (this.database = (ev.target as IDBOpenDBRequest).result);
          this.isReady.next();
        };
        req.onupgradeneeded = (ev) => {
          this.sureStoresExist(dbStructure.stores, (ev.target as IDBOpenDBRequest).result);
          this.deleteStores(dbStructure.storesToDelete, (ev.target as IDBOpenDBRequest).result);
        };
      });
  }

  private sureStoresExist(stores: { name: string; key: string }[], database?: IDBDatabase): void {
    if (!database) return;
    stores.forEach((storeInfo) => {
      if (database.objectStoreNames.contains(storeInfo.name)) return;
      const store = database.createObjectStore(storeInfo.name, { keyPath: storeInfo.key });
      store.createIndex(`${storeInfo.name}_${storeInfo.key}_unqiue`, storeInfo.key, { unique: true });
    });
  }

  private deleteStores(storeNames: string[], database?: IDBDatabase): void {
    if (!database) return;
    storeNames.forEach((name) => {
      if (database.objectStoreNames.contains(name)) {
        database.deleteObjectStore(name);
      }
    });
  }
}
